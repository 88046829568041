import React, { useState } from "react";

import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { Space } from "antd";
import classNames from "classnames";

import { getVeriffUrl } from "api/kyc";
import { DocumentSuccessSVG } from "assets/icons";
import { BackButton } from "components/BackButton";
import { ConfirmButton } from "components/ConfirmButton";
import { SubmitButton } from "components/SubmitButton";
import { HOME_PATH } from "constants/pages";
import { useKycData, useMutateKyc, useStepsProgress } from "hooks";

import styles from "./ProofOfIdentityCreated.module.scss";

export const ProofOfIdentityCreated = () => {
  const { goToNextScreen, setStepsPercent, setCurrentStep } = useStepsProgress();
  const [isVerifying, setIsVerifying] = useState(true);
  const updateKyc = useMutateKyc();
  const { data } = useKycData();

  const handleNext = () => {
    updateKyc.mutateAsync({ currentStep: data?.last.poaCarriedOver ? 7 : 3 }).then(() => {
      goToNextScreen();
    });
  };

  const header = "Your Proof of Identity has been confirmed";
  const paragraph = "You may continue to next stage";

  const handleVeriffConfirm = () => {
    getVeriffUrl().then(({ url }) => {
      setStepsPercent(15);
      createVeriffFrame({
        url,
        onEvent: (msg) => {
          switch (msg) {
            case MESSAGES.FINISHED: {
              updateKyc.mutateAsync({ currentStep: data?.last.poaCarriedOver ? 7 : 3 }).then(() => {
                setCurrentStep(3);
                setIsVerifying(true);
                goToNextScreen();
              });
              break;
            }
            case MESSAGES.CANCELED: {
              setStepsPercent(5);
              setIsVerifying(false);
              break;
            }
          }
        },
      });
    });
  };

  const confirmationProps = {
    className: classNames([styles.resetVeriffButton, styles.margin0]),
    onConfirmHandler: handleVeriffConfirm,
    title: "Veriff session reset",
    content: "This action will override your current Veriff session. Are you sure you want to proceed?",
  };

  return (
    <div className={styles.proofOfIdentityCreated}>
      <h2>{header}</h2>
      <p>{paragraph}</p>
      <div className={styles.documentIconContainer}>{<DocumentSuccessSVG />}</div>

      <Space align='center' className={styles.actions} direction='vertical' size={0}>
        <SubmitButton onClick={handleNext}>Continue</SubmitButton>

        <BackButton href={HOME_PATH} className={styles.backToDashboard}>
          Back to Dashboard
        </BackButton>

        <ConfirmButton {...confirmationProps} className={styles.resetVeriffButton}>
          Restart Proof Of Identity Verification
        </ConfirmButton>
      </Space>
    </div>
  );
};
